<template>
  <div class="opentuan">
    <van-search
      v-model="keywords"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch"
      shape="round"
      class="van-hairline--bottom"
    >
      <template #left>
        <van-icon name="arrow-left" @click="onClickLeft" />
      </template>
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <van-tabs v-model="active" @click="onTabsClick">
      <van-tab v-for="(val, key) in tabsList" :key="key" :title="val.title">
        <div class="main" v-if="!val.nodata">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getList"
            :immediate-check="false"
          >
            <van-cell center v-for="(item, index) in val.list" :key="index">
              <template #title>
                <van-image
                  :src="item.avatar"
                  width="60"
                  height="60"
                  round
                  fit="cover"
                  lazy-load
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </template>
              <template #default>
                <div class="list_title">
                  <div class="van-ellipsis list_title_text">{{item.nickname}}</div>
                  <div class="list_title_tag">
                    <van-tag round color="#FDE7EA" text-color="#F23A5E">{{item.levelname}}</van-tag>
                  </div>
                </div>
                <div class="level_list">
                  <van-tag round color="#fffbe8" text-color="#ed6a0c" @click="copayAgentCode(item.agent_code)">邀请码：{{item.agent_code}}</van-tag>
                </div>
              </template>
              <template #right-icon>
                <div v-if="item.status == 0">
                  <div>
                    <van-button
                      type="danger"
                      size="mini"
                      plain
                      round
                      @click="setOpenTuan(index, 1)"
                    >
                      同意
                    </van-button>
                  </div>
                  <div style="margin-top: 10px;">
                    <van-button
                      size="mini"
                      round
                      @click="setOpenTuan(index, 2)"
                    >
                      拒绝
                    </van-button>
                  </div>
                </div>
                <div v-else class="status_str">
                  <van-tag round :color="item.status == 1 ? '#FDE7EA': '#ececec'" :text-color="item.status == 1 ? '#F23A5E' : '#333'">{{item.status_str}}</van-tag>
                </div>
              </template>
            </van-cell>
          </van-list>
        </div>
        <div class="main" v-if="val.nodata">
          <van-empty description="暂无数据" />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {
  name: "Opentuan",
  data() {
    return {
      active: 0,
      size: 30,
      tabsList: [
        {
          index: 0,
          title: '全部',
          list: [],
          CurrOffset: 0,
          finished: false,
          nodata: false,
        },
        {
          index: 1,
          title: '待审核',
          list: [],
          CurrOffset: 0,
          finished: false,
          nodata: false,
        },
        {
          index: 2,
          title: '已同意',
          list: [],
          CurrOffset: 0,
          finished: false,
          nodata: false,
        },
        {
          index: 3,
          title: '已拒绝',
          list: [],
          CurrOffset: 0,
          finished: false,
          nodata: false,
        }
      ],
      list: [],
      keywords: "",
      loading: false,
      finished: false,
      CurrOffset: 0,
      CurrSize: 30,
      nodata: false,
      loadingTrue: true,
    }
  },
  mounted() {
    this.init();
    this.active = this.$route.query.status ? parseInt(this.$route.query.status) : 0;
    console.log("mounted");
  },
  methods: {
    init() {
      console.log("init");
      this.getList();
    },
    // 获取列表
    getList() {
      if (this.loadingTrue) {
        this.loadingTrue = false;
        this.loading = true;
        console.log("getList");
        let that = null;
        that = this;
        that.list = that.tabsList[that.active].list;
        that.CurrOffset = that.tabsList[that.active].CurrOffset;
        that.finished = that.tabsList[that.active].finished;
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "shopsmobile/member/getOpentuan",
            that.$qs.stringify({
              offset: that.CurrOffset,
              size: that.CurrSize,
              keywords: that.keywords,
              active: that.active
            })
          )
          .then(res => {
            console.log(res);
            that.loading = false;
            that.finished = false;
            that.nodata = false;
            that.loadingTrue = true;
            if (res.data.code == 100000) {
              if (that.CurrOffset == 0) {
                that.list = res.data.data;
              }else {
                res.data.data.forEach(item => {
                  that.list.push(item);
                })
              }

              if (res.data.data.length < that.CurrSize) {
                that.finished = true;
              }
              that.CurrOffset += res.data.data.length;
              that.tabsList[that.active].list = that.list;
              that.tabsList[that.active].CurrOffset = that.CurrOffset;
              that.tabsList[that.active].finished = that.finished;
              that.tabsList[that.active].nodata = that.nodata;
              console.log(that.tabsList[that.active], that.finished, "that.tabsList[that.active]");
            }else {
              that.finished = true;
              if (that.CurrOffset == 0) {
                that.nodata = true;
              }
              that.tabsList[that.active].finished = that.finished;
              that.tabsList[that.active].nodata = that.nodata;
            }
          })
          .catch(err => {
            console.log(err);
            that.finished = true;
            if (that.CurrOffset == 0) {
              that.nodata = true;
            }
            that.loadingTrue = true;
            that.tabsList[that.active].finished = that.finished;
            that.tabsList[that.active].nodata = that.nodata;
          })
      }
    },
    // 搜索
    onSearch() {
      console.log("onSearch");
      this.active = 0;
      this.tabsList[0].CurrOffset = 0;
      this.tabsList[0].list = [];
      this.tabsList[0].finished = false;
      this.loadingTrue = true;
      this.getList();
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    setOpenTuan(index, type) {
      let str = "";
      str = "您确定要同意该用户开团吗？";
      if (type == 2) {
        str = "您确定要拒绝该用户开团吗？";
      }
      this.$dialog.confirm({
        title: '开团申请',
        message: str,
      })
      .then(() => {
        // on confirm
        this.setKaituan(index,type);
      })
      .catch(() => {
        // on cancel
      });
    },
    // 切换标签
    onTabsClick(name, title) {
      console.log(name, title);
      this.active = name;
      this.tabsList[this.active].CurrOffset = 0;
      this.tabsList[this.active].list = [];
      this.tabsList[this.active].finished = false;
      this.tabsList[this.active].nodata = false;
      this.loadingTrue = true;
      this.getList();
    },
    setKaituan(index,type) {
      let id = 0;
      id = this.tabsList[this.active].list[index].memberid;
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/member/setOpentuan",
          that.$qs.stringify({
            mid: id,
            type: type
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "审核成功");
            that.tabsList[that.active].list[index].status = type;
            that.tabsList[that.active].list[index]['status_str'] = type == 1 ? '审核已通过' : '审核不通过';
          }else {
            that.$toast(res.data.msg ? res.data.msg : "审核失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 复制邀请码
    copayAgentCode(code) {
      let _that = null;
      _that = this;
      _that.$copyText(code)
      .then(
        function(e) {
          _that.$toast("邀请码复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },
  }
}
</script>
<style lang="less">
.opentuan {
  background-color: #ececec;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  &:last-child {
    padding-bottom: 50px;
  }
  .van-search {
    padding-left: 0;
    .van-icon-arrow-left,
    .van-search__action {
      padding: 0 10px;
      line-height: 34px;
    }
    .van-cell__value {
      flex: 1;
    }
  }
  .main {
    width: 100%;
    overflow: hidden;
    .van-cell {
      .van-cell__title {
        padding: 0 10px 0 0;
        overflow: hidden;
        .van-image {
          display: block;
        }
      }
      .van-cell__value {
        flex: 1;
        text-align: left;
        .list_title {
          display: flex;
          overflow: hidden;
          .list_title_text {
            overflow: hidden;
          }
          .list_title_tag {
            min-width: 85px;
            flex: 1;
            margin: 0 10px;
            .van-tag {
              margin-right: 5px;
              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
        .level_list {
          margin-top: 5px;
          display: flex;
          justify-items: center;
          .van-tag {
            padding: 1px 5px;
            margin-right: 5px;
            &:last-of-type {
              margin-right: 0px;
            }
          }
        }
      }
      .van-button {
        padding: 0 16px;
      }
      .status_str {
        .van-tag {
          padding: 4px 10px;
        }
      }
    }
  }
}
</style>